<template>
  <v-btn
    small
    style="border-radius: 999px"
    v-bind="buttonProperties"
    :color="color"
    :to="route"
    :href="href"
    :target="linkTarget"
    class="mx-2"
    @click.native="onClick"
  >
    <v-icon v-if="icon" left>{{icon}}</v-icon>
    <span>{{ label|t }}</span>
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import listMetaAction from '@/components/list/listMetaAction/listMetaAction.js'
export default {
  mixins: [listMetaAction],
}
</script>

<style lang="sass" type="text/sass" scoped></style>